.react-tel-input {
  .selected-flag {
    width: 38px;
    border-radius: 0.25rem;

    &:hover,
    &:focus {
      background-color: lightgrey;
    }
  }

  .country-list {
    .search {
      padding-right: 10px;
    }
  }
}
