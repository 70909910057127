body,
#app,
#content {
  display: grid;
  min-height: 100vh;
  position: relative;
}

.animation-wrapper {
  height: 100%;
}

.animation-wrapper > * {
  position: absolute;
  left: 0;
  right: 0;
}

// Fade
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 450ms linear 250ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms linear;
}

//Slide in
.slideIn-appear {
  transform: translateX(30px);
  opacity: 0;
}
.slideIn-appear.slideIn-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.6s linear;
}
.slideIn-enter {
  opacity: 0;
  transform: translateX(30px);
}
.slideIn-enter.slideIn-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s linear 0.4s;
}
.slideIn-leave {
  opacity: 1;
  transform: translateX(0);
}
.slideIn-leave.slideIn-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(-30px);
  transition: all 0.2s linear;
}
